<template>
  <v-container v-if="all_loaded" id="users-table" fluid tag="section">
    <v-form
      @submit.stop.prevent="get_users_by_page()"
      ref="form"
      v-model="valid"
      :lazy-validation="lazy"
      data-test="Admin:BankersTable:FormSearch"
    >
      <v-row v-if="filter" justify="center" class="mt-6">
        <v-text-field
          class="mx-3"
          dense
          outlined
          :label="$t('search')"
          v-model="name_filter"
          :style="isMobile() ? 'max-width: 25%' : 'max-width: 35%'"
          data-test="Admin:BankersTable:TxtSearchBar"
        ></v-text-field>
        <v-select
          class="mx-3"
          style="max-width: 30%"
          outlined
          dense
          :label="'Ordenar Por'"
          :items="filter_order_options"
          v-model="filter_order_selected"
          item-text="text"
          item-value="index"
        >
        </v-select>
        <v-btn class="mx-3 secondary" type="submit">{{ $t("apply") }}</v-btn>
      </v-row>
    </v-form>
    <v-simple-table>
      <thead>
        <tr>
          <th class="text-center">
            {{ $t("name") }}
            <v-icon
              small
              :color="orderBy == -1 ? 'primary' : ''"
              class="ml-2"
              @click="header_click(-1)"
              >{{ orderByMode == 0 ? "mdi-arrow-up" : "mdi-arrow-down" }}
            </v-icon>
          </th>
          <th class="text-center">{{ $t("email") }}</th>
          <th class="text-center">
            {{ $tc("user", 2) }}
            <v-icon
              small
              :color="orderBy == 3 ? 'primary' : ''"
              class="ml-2"
              @click="header_click(3)"
              >{{ orderByMode == 0 ? "mdi-arrow-up" : "mdi-arrow-down" }}
            </v-icon>
          </th>
          <th class="text-center">
            {{ $t("invested") }}
            <v-icon
              small
              :color="orderBy == 1 ? 'primary' : ''"
              class="ml-2"
              @click="header_click(1)"
              >{{ orderByMode == 0 ? "mdi-arrow-up" : "mdi-arrow-down" }}
            </v-icon>
          </th>
          <th class="text-center">
            {{ $tc("investment", 2) }}
            <v-icon
              small
              :color="orderBy == 2 ? 'primary' : ''"
              class="ml-2"
              @click="header_click(2)"
              >{{ orderByMode == 0 ? "mdi-arrow-up" : "mdi-arrow-down" }}
            </v-icon>
          </th>
          <th class="text-center">
            {{ $t("allocations_pending") }}
            <v-icon
              small
              :color="orderBy == 4 ? 'primary' : ''"
              class="ml-2"
              @click="header_click(4)"
              >{{ orderByMode == 0 ? "mdi-arrow-up" : "mdi-arrow-down" }}
            </v-icon>
          </th>
          <th class="text-center">{{ $t("download_users_report") }}</th>
          <th class="text-center">{{ $t("options") }}</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(user, index) in users" :key="index">
          <td
            class="text-center"
            :data-test="`Admin:BankersTable:TdName:${show_name(user)}`"
          >
            <div class="div-user-list-name">{{ show_name(user) }}</div>
            <div v-if="user.Deleted" class="div-deleted">
              {{ $t("deleted_user") }}
            </div>
          </td>
          <td class="text-center">{{ user.Email }}</td>
          <td class="text-center">
            {{ user.TotalUsers != 0 ? user.TotalUsers : "-" }}
          </td>
          <td class="text-center">
            <div class="table-gap" v-if="user.TotalInvested.length">
              <span v-for="(item, i) in user.TotalInvested" :key="i">{{
                formatCurrency(item.Currency, item.Value)
              }}</span>
            </div>
            <span v-else>-</span>
          </td>
          <td class="text-center">
            {{ user.TotalInvestments != 0 ? user.TotalInvestments : "-" }}
          </td>
          <td class="text-center">
            <div class="table-gap" v-if="user.TotalAllocated.length">
              <span v-for="(item, i) in user.TotalAllocated" :key="i">{{
                formatCurrency(item.Currency, item.Value)
              }}</span>
            </div>
            <span v-else>-</span>
          </td>
          <td class="text-center">
            <v-btn
              class="px-2 ml-1 secondary"
              :loading="user.ReportDownloading"
              @click="download_users_report(user)"
              min-width="0"
              small
            >
              <v-icon small>mdi-download</v-icon>
            </v-btn>
          </td>
          <td class="text-center">
            <v-btn
              class="px-2 ml-1 secondary"
              @click="edit_user(user.Id)"
              min-width="0"
              small
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              class="px-2 ml-1"
              @click="open_delete_dialog(user)"
              color="red"
              min-width="0"
              small
              :disabled="user.Deleted"
            >
              <v-icon small>mdi-trash-can</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-pagination
      color="secondary"
      @input="changePage"
      :length="total_pages"
      v-model="page"
    >
    </v-pagination>
    <v-dialog v-if="delete_dialog" v-model="delete_dialog">
      <DeleteConfirmationModal
        :obj="delete_obj"
        :name="delete_obj.Name + ' ' + delete_obj.LastName"
        @close="delete_dialog = false"
        @delete="delete_user"
      ></DeleteConfirmationModal>
    </v-dialog>
  </v-container>
  <v-container v-else>
    <v-progress-circular
      style="margin-left: 50%"
      indeterminate
      size="70"
      color="primary"
    ></v-progress-circular>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import DeleteConfirmationModal from "@/views/pages/DeleteConfirmationModal";
import moment from "moment";
import { TableEnum } from "@/shared/enums/Table";
import { UserTypeEnum } from "@/shared/enums/UserType";
import { formatCurrency } from "@/shared/helpers/currencyHelper";

export default {
  name: "BankerTable",

  components: {
    DeleteConfirmationModal,
  },

  data: () => ({
    apiService: new ApiService(),
    all_loaded: false,
    filter: true,
    page: 1,
    total_pages: 1,
    name_filter: "",
    valid: true,
    lazy: false,
    filter_order_selected: -1,
    delete_obj: null,
    delete_dialog: false,
    userId: null,
    users: [],
    orderBy: null,
    orderByMode: 0,
    TableEnum: TableEnum,
    tab: null,
    userTypeEnum: UserTypeEnum,
    formatCurrency: formatCurrency,
  }),
  props: {
    changePageRouterPush: {
      type: Boolean,
      default: true,
    },
  },
  async created() {
    this.get_users_by_page();
  },
  computed: {
    filter_order_options() {
      return [
        {
          index: -1,
          text: this.$t("name"),
        },
        {
          index: 0,
          text: this.$tc("partner", 1),
        },
        {
          index: 1,
          text: this.$t("invested"),
        },
        {
          index: 2,
          text: this.$tc("investment", 2),
        },
        {
          index: 3,
          text: this.$tc("user", 2),
        },
        {
          index: 4,
          text: this.$t("allocations_pending"),
        },
      ];
    },
  },
  methods: {
    isMobile() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return true;
      }
      return false;
    },
    header_click(col) {
      this.orderBy = col;
      if (this.orderByMode == 0) {
        this.orderByMode = 1;
      } else {
        this.orderByMode = 0;
      }
      this.get_users_by_page();
    },
    get_users_by_page() {
      var page = this.page;
      if (this.$route.query.page) {
        page = parseInt(this.$route.query.page);
      }
      var params = {
        Page: page,
        Order: this.filter_order_selected,
      };
      if (this.name_filter) {
        params.NameFilter = this.name_filter;
      }
      if (this.orderBy != null) {
        params.Order = this.orderBy;
        params.OrderMode = this.orderByMode;
      }
      this.apiService
        .postRequest("banker/list/filter", params)
        .then((resp) => {
          var json = JSON.parse(resp.message);
          this.page = json.Pager.CurrentPage;
          this.total_pages = json.Pager.TotalPages;
          this.users = json.Users;
          this.all_loaded = true;
        })
        .catch((error) => {
          this.all_loaded = true;
        });
    },
    format_to_currency: function (value) {
      var formatter = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
      return formatter.format(value);
    },
    changePage() {
      if (this.changePageRouterPush) {
        this.$router.push({
          path: "/users",
          query: { page: this.page, tab: TableEnum.BANKERS },
        });
      }

      this.get_users_by_page();
    },
    open_delete_dialog: function (user) {
      this.delete_dialog = true;
      this.delete_obj = user;
    },
    download_users_report(p) {
      p.ReportDownloading = true;
      this.apiService
        .getRequest(`banker/generate-users-report/${p.Id}`)
        .then((resp) => {
          resp = "data:application/xlsx;base64," + resp.message;
          const link = document.createElement("a");
          link.href = resp;
          link.setAttribute(
            "download",
            `${p.Name} ${p.LastName} - ${moment().format("DD-MM-YYYY")}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          p.ReportDownloading = false;
        })
        .catch((error) => {
          p.ReportDownloading = false;
        });
    },
    show_name: function (user) {
      if (user.Name === null) {
        return "";
      } else {
        return user.Name.concat(" ").concat(user.LastName);
      }
    },
    edit_user: function (user_id) {
      var userType = JSON.parse(localStorage.getItem("user")).type;
      if (userType == this.userTypeEnum.Partner) {
        this.$router.push({
          path: `/banker/edit/${user_id}`,
          params: { user_id: user_id },
        });
      } else {
        this.$router.push({
          path: `/users/edit/${user_id}`,
          params: { user_id: user_id },
        });
      }
    },
    delete_user: function (user) {
      this.all_loaded = false;
      this.apiService
        .deleteRequest("user/delete", `"${user.Id}"`)
        .then((resp) => {
          var index = this.users.indexOf(user);
          this.users[index].Deleted = true;
          this.delete_dialog = false;
          this.all_loaded = true;
        })
        .catch((error) => {
          this.all_loaded = true;
        });
    },
  },
};
</script>

<style scoped>
.table-gap > span {
  display: block;
}

.table-gap > span + span {
  margin-top: 4px;
}
</style>
