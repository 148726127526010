export const TableEnum = {
  INVESTOR: 0,
  ADMIN: 1,
  PARTNER: 2,
  TRANSLATOR: 3,
  FINANCIALANALYSTS: 4,
  CONTENTANALYSTS: 5,
  BANKERS: 6,
  ALPHAANALYSTS: 7,
  MANAGER_USER: 8,
};
